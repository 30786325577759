import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import { formatRichText } from "../utils"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import Link from "../components/link";
import { getSinglePage } from "../utils";
import { CustomHr } from "../components/CustomHr"

const PlainPage = ({ data, pageContext }) => {
  const { plainpage, headers, footers, general } = data.strapi; 
  const { copyright } = plainpage;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode , fontColorDarkMode, fontColorLightMode } = general; 

  const { language, slug } = pageContext
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const columns = formatRichText(plainpage.richText);
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon';
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = plainpage.sup +" "+ plainpage.heading;
  const seoTitle = plainpage &&  plainpage.seo && plainpage.seo.metaData.metaTitle || pageFirstHeading|| "Plain page";
  const seoDescription = plainpage && plainpage.seo && plainpage.seo.metaData.metaDescription || pageFirstHeading|| "Plain page";
  const seoKeywords = plainpage && plainpage.seo && plainpage.seo.metaData?.keywords;

  const allPages = data?.allSitePage?.nodes; 
  const webpages = plainpage && plainpage.webpages || {}; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  } 

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="plain" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={plainpage.darkTheme} 
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        <section className={`section ${slug} section-small-margin`} style={{backgroundColor: sectionBackgroundColor}}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <header className="header header--plainpage">
                  <h1 className="header__heading header__heading--space">
                    <small className="header__preheading">{plainpage.sup}</small>
                    {plainpage.heading}
                  </h1>
                </header>
              </div>
            </div>
            <div className="row">
              {columns.map((richText, index) => {
                return (
                  <div key={index} className="col-12 col-md-6 rich-text">
                    <Markdown options={{
                      overrides: {a: {
                        component: Link
                      },                           
                      hr: { 
                          component: CustomHr,
                          props: {
                            borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                          }
                        }  
                      }
                    }}>
                      {richText}
                    </Markdown>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </SiteHeaderAlt>
    </Layout>
  )
}

export default PlainPage

export const pageQuery = graphql`
  query PlainPage($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
      }
      plainpage(id: $id) {
         copyright
         darkTheme
         webpages {
            about_us {
              language
            }
            contact {
              language
            }
            case_study {
              slug
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        sup
        heading
        richText {
          richText
        }
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`
